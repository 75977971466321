import React, { useContext, useEffect, useState } from 'react';
import { RoomCard } from './component/RoomCard'; // Importing Room as a type
import { type Room } from "./models/Room";
import { CreateUser, CreateRoom, ListRooms } from './client'
import { User } from './models/User';
import { AppContext } from './models/AppContext';



interface AppProps {
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const App: React.FC<AppProps> = ({ setUser }) => {
  const { user, token, setToken } = useContext(AppContext);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [newUserName, setNewUserName] = useState("");
  const [newRoomName, setNewRoomName] = useState("");

  const handleLogin = async (event: any) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      const data = await response.json();
      console.log(data.token);
      setToken(data.token);
      console.log('Login successful', data);
    } catch (error) {
      console.error('Login failed', error);
      alert('Login failed. Please try again.');
    }
  };

  const handleLogout = () => {
    setToken(null); // This will clear the token and user
    setUser(null); // Optionally clear user state
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const rooms: Room[] = await ListRooms();
        setRooms(rooms);
      } catch (err) {
        console.log(err);
      }
    };
  
    fetchData();
  }, []);

  const createUser = async () => {
    try {
      const newUser: User = await CreateUser(newUserName);
      setUser(newUser);
      console.log("Created user")
      setNewUserName(""); // Clear input field after adding user
    } catch (error) {
      console.log(error);
    }
  };

  const createRoom = async () => {
    if (!user) {
      console.log("Trying to create room without valid user")
      return
    }

    try {
      await CreateRoom(newRoomName, user.ID)
      const rooms: Room[] = await ListRooms()
      setRooms(rooms);
      console.log(rooms);
      setNewRoomName(""); // Clear input field after adding room
    } catch (error) {
      console.log(error);
    }
  };

  const inputForUser = () => {
    if (user) {
      return '';
    } 

    return (
        <div>
          <input
          name='User name'
          value={newUserName}
          onChange={(e) => setNewUserName(e.target.value)}
        />
        <button onClick={createUser}>Add new user</button>
        </div>
    )
  };
  
  const inputForRoom = () => {
    if (!user) {
      return '';
    }

    return (
      <div>
        <input
          name='Room name'
          value={newRoomName}
          onChange={(e) => setNewRoomName(e.target.value)}
        />
        <button onClick={createRoom}>Add new room</button>
      </div>
    );
  };

  const showRooms = () => {
    if (!user || !rooms || rooms.length === 0) {
      return;
    }

    return (
      <div>
      {
        rooms.map((room: Room) => (
            <RoomCard key={room.ID} room={room} user={user} />
          )
        )
      }
    </div>
    )
  }

  return (
    <div className="App">
      <h1>React WebRTC Example</h1>
      {!token ? (
        <div className="Login">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <div>
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" name="username" />
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" name="password" />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      ) : (
        <>
          <div className="Users">
            <h2>User</h2>
            {user ? 'Hello ' + user.Name : 'Create a user'}
            {inputForUser()}
          </div>
          <div className="Rooms">
            <h2>Rooms</h2>
            {showRooms()}
            {inputForRoom()}
          </div>
          <button onClick={handleLogout}>Logout</button>
        </>
      )}
    </div>
  );
};

export default App;
