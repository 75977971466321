import { Room } from "../models/Room";
import { useNavigate } from 'react-router-dom';
import { User } from "../models/User";


interface RoomCardProps {
    room: Room;
    user: User;
}

// Update the RoomCard component to accept RoomCardProps
export const RoomCard: React.FC<RoomCardProps> = ({ room, user }) => {
    const navigate = useNavigate();

    const joinRoom = async () => {
        try {
            navigate('rooms/' + room.ID)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <p>{room.Name}</p>
            <button onClick={joinRoom}>Join room</button>
        </div>
    );
};

export default RoomCard;