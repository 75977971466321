import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from './error-page';
import RoomPage from './component/RoomPage';
import { User } from './models/User';
import { AppContext, AppContextInterface } from './models/AppContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function AppWrapper() {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(localStorage.getItem('token')); // Manage token state

  const appContextValue: AppContextInterface = {
    user: user,
    token: token,
    setToken: (newToken: string | null) => {
      setToken(newToken);
      // This is super hacky but we just want something... anything to work
      if (newToken) {
        localStorage.setItem('token', newToken); // Persist token in local storage
      } else {
        localStorage.removeItem('token');
      }
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App setUser={setUser} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "rooms/:roomID",
      element: <RoomPage />
    }
  ]);

  return (
    <AppContext.Provider value={appContextValue}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

root.render(<AppWrapper />);

// Performance logging
reportWebVitals();
