import { useContext, useEffect, useRef, useState } from "react";
import { Room } from "../models/Room";
import { convertRoom } from "../client";
import { useParams } from "react-router-dom";
import { AppContext } from "../models/AppContext";

const mediaConstraints = {
  audio: true, // We want an audio track
  video: true, // And we want a video track
};

const RoomPage = () => {
  const [room, setRoom] = useState<Room>();
  const { user } = useContext(AppContext);

  const [socket, setSocket] = useState<WebSocket>();
  const { roomID } = useParams();

  const localVideoRef = useRef<HTMLVideoElement>(null);
  const [localStream, setLocalStream] = useState<MediaStream | null>(null);

  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);

  const remoteVideoRef = useRef<HTMLVideoElement>(null);

  console.log("peerConnection", peerConnection);

  if (!socket && user) {
    const ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/api/v1/rooms/${roomID}/join`);
    setSocket(ws);
    console.log("Websocket set");
    
    ws.onopen = () => {
      if (!user) {
        return;
      }
      const message = JSON.stringify({
        "user_id": user.ID,
      });
      ws.send(message);
      setupWebRTC(ws);
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleWebSocketMessage(ws, message);
    };

    ws.onclose = () => {
      console.log('WebSocket is closed now.');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  const setupWebRTC = (socket: WebSocket) => {
    console.log("Setup WebRTC started")
    const pc = new RTCPeerConnection({
      iceServers: [
        { urls: `stun:${process.env.REACT_APP_API_DOMAIN}:3478` }
      ]
    });

    pc.onicecandidate = event => {
      if (event.candidate && socket) {
        socket.send(JSON.stringify({ type: 'ice-candidate', candidate: event.candidate }));
      }
    };

    pc.ontrack = event => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = event.streams[0];
      }
    };

    if (localStream) {
      localStream.getTracks().forEach(track => {
        pc.addTrack(track, localStream);
      });
    }

    setPeerConnection(pc);
    console.log("Peer connection set")

    // Create and send an offer
    pc.createOffer().then(offer => {
      console.log("Start create offer");
      pc.setLocalDescription(offer);
      
      if (socket) {
        socket.send(JSON.stringify({ type: 'offer', offer }));
      } else {
        console.error("Socket not set when wanting to create offer");
      }
    }).catch(error => {
      console.error('Error creating offer: ', error);
    });
  };
  
  const handleWebSocketMessage = async (socket: WebSocket, message: any) => {
    console.log("Got message", message);
    if (message.type === 'join') {
      if (room) {
        console.log("Room already set");
        return
      }
      const convertedRoom = convertRoom(message.room);
      setRoom(convertedRoom);
    } else if (message.type === 'offer') {
      console.log("Handling offer!");
      if (peerConnection && socket) {
        await peerConnection.setRemoteDescription(new RTCSessionDescription(message.offer));
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
        socket.send(JSON.stringify({ type: 'answer', answer }));
      } else {
        console.log("Unable to handle 'offer'", peerConnection, socket)
      }
    } else if (message.type === 'answer') {
      if (peerConnection) {
        await peerConnection.setRemoteDescription(new RTCSessionDescription(message.answer));
      }
    } else if (message.type === 'ice-candidate') {
      const candidate = new RTCIceCandidate(message.candidate);
      if (peerConnection) {
        await peerConnection.addIceCandidate(candidate);
      }
    } else {
      console.log("Unknown event", message);
    }
  }

  // SETUP VIDEO OF THE USER THEMSELF
  useEffect(() => {
    navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
      setLocalStream(stream);
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
    });
  }, []);

  if (room) {
    const participants = room.Participants.map(i => <p>{i.User.Name} : {i.User.ID}</p>)
    return (
        <div>This is a specific room: {room.ID} {room.Name} with participants {participants}
          <h1>You are {user?.ID}</h1>
          <div>
            <p>Video of other participant: </p>
            <video ref={remoteVideoRef} id="received_video" autoPlay muted></video>
            <p>Video of yourself: </p>
            <video ref={localVideoRef} id="local_video" autoPlay muted ></video>
          </div>
        </div>
        
    );
  }
  return (
      <div>Fetching room...</div>
  )
}

export default RoomPage;