import { createContext, useState, ReactNode } from 'react';
import { User } from './User';


export interface AppContextInterface {
    user: User | null;
    token: string | null;
    setToken: (token: string | null) => void;
}

export const AppContext = createContext<AppContextInterface>({
    user: null,
    token: null,
    setToken: () => {},
});

interface AppProviderProps {
    children: ReactNode;
}


export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);
    const [user] = useState<User | null>(null);

    return (
        <AppContext.Provider value={{ user, token, setToken }}>
            {children}
        </AppContext.Provider>
    );
};