import type { Room, Participant } from "./models/Room";
import { User } from "./models/User";


const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1`;


export const CreateUser = (name: string): Promise<User> => {
    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify content type as JSON
        },
        body: JSON.stringify({
            "name": name
        }),
    }

    return fetch(baseUrl + '/users', options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data: ExternalUser) => {
            return convertUser(data);
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            throw error; // Re-throw the error for handling in the caller
        });
}

export const CreateRoom = (name: string, userID: string): Promise<Room> => {
    
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name": name,
            "user_id": userID,
        }),
    }

    return fetch(baseUrl + '/rooms', options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data: ExternalRoom) => {
            return convertRoom(data);
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            throw error; // Re-throw the error for handling in the caller
        });
}


export const JoinRoom = (roomID: string, userID: string): Promise<Room> => {
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "user_id": userID,
        }),
    }

    return fetch(baseUrl + '/rooms/' + roomID + "/join", options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data: ExternalRoom) => {
            return convertRoom(data);
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            throw error; // Re-throw the error for handling in the caller
        });
}


interface ExternalUser {
    id: string;
    name: string;
}

interface ExternalParticipant {
    user: ExternalUser;
    host: boolean;
}

interface ExternalRoom {
    id: string;
    name: string;
    participants: ExternalParticipant[];
}

const convertUser = (external: ExternalUser): User => {
    return {
        "ID": external.id,
        "Name": external.name,
    } as User;
};

const convertParticipant = (external: ExternalParticipant): Participant => {
    return {
        "User": convertUser(external.user),
        "Host": external.host,
    } as Participant
};

export const convertRoom = (external: ExternalRoom): Room => {
    const participants = external.participants.map(item => convertParticipant(item))
    return {
        "ID": external.id,
        "Name": external.name,
        "Participants": participants
    }
};

interface RoomsResponse {
    rooms: ExternalRoom[];
}

export const ListRooms = (): Promise<Room[]> => {
    return fetch(baseUrl + '/rooms')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data: RoomsResponse) => {
            return data.rooms.map(item => convertRoom(item));
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            throw error; // Re-throw the error for handling in the caller
        });
}

export const GetRoom = (id: string): Promise<Room> => {
    return fetch(baseUrl + '/rooms/' + id)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data: ExternalRoom) => {
            return convertRoom(data)
        })
        .catch(error => {
            console.error('There was a problem with your fetch operation:', error);
            throw error; // Re-throw the error for handling in the caller
        });
}


export default CreateRoom;